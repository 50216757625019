<template>
  <div>
    <NuxtLoadingIndicator color="#e2241b" class="fixed inset-x-0 top-0" />
    <div class="flex flex-col justify-between h-screen font-ff-condensed">
      <Offline />
      <Navigation v-if="!isMobile" />
      <MobileNavigation v-else />

      <main
        id="app"
        class="relative w-full mx-auto mb-auto"
        style="max-width: 2560px"
      >
        <slot />
      </main>

      <Newsletter />

      <Footer />
      <client-only>
        <ModalsContainer />
      </client-only>
      <CookieBanner />
    </div>
  </div>
</template>

<script setup>
import { ModalsContainer } from 'vue-final-modal'
import { useMainStore } from '@/store/index'
import { useLocaleStore } from '@/store/locales'
import { useAppStore } from '~/store/app'
import { useModal } from 'vue-final-modal'
import ResetAccountPasswordModal from '~/modals/ResetAccountPasswordModal.vue'

// use methods
const route = useRoute()
const router = useRouter()
const mainStore = useMainStore()
const localeStore = useLocaleStore()
const nuxtApp = useNuxtApp()
const appStore = useAppStore()

//data
const $i18nPath = nuxtApp.$i18nPath
const isMobile = ref(false)
const windowWidth = ref(0)

onServerPrefetch(async () => {
  // ✅ this will work
  await mainStore.fetchInitial()
})

watch(
  route,
  (newValue, oldValue) => {
    // this.$store.dispatch('menu/hideAll')
    openResetAccountPasswordModal()
  },
  { deep: true }
)

// computed
const isStorePage = computed(() => {
  return route.path.startsWith($i18nPath(mainStore.pageLinks['stores']))
})

const isAboutUsPage = computed(() => {
  return route.path.startsWith($i18nPath(mainStore.pageLinks['about-us']))
})

const isHomePage = computed(() => {
  return route.name == 'country'
})

onBeforeMount(() => {
  if (import.meta.browser && nuxtApp.$pusher) {
    const channel = nuxtApp.$pusher.subscribe('deployments')
    channel.bind('new-deploy', (data) => appStore.setForceReload(true))
  }
})

onMounted(async () => {
  windowWidth.value = window.innerWidth
  if (windowWidth.value <= 1023) {
    isMobile.value = true
  } else {
    isMobile.value = false
  }

  await nextTick()
  window.addEventListener('resize', onResize)
  // nuxtApp.$loadGTM()

  const countryLocale = useCookie('CountryLocale', {
    path: '/',
    watch: 'shallow',
  })
  countryLocale.value = `${mainStore.country}/${mainStore.locale}`

  if (!isStorePage.value && nuxtApp.$loadIntercom) {
    nuxtApp.$loadIntercom()
  }

  openResetAccountPasswordModal()
})

onBeforeUnmount(() => {
  window.onscroll = null
  window.removeEventListener('resize', onResize)
})

async function openResetAccountPasswordModal() {
  if (route.query && route.query.token) {
    await nuxtApp
      .$api(`api/auth/password/find/${route.query && route.query.token}`)
      .then((res) => {
        if (res.data) {
          const { open, close } = useModal({
            component: ResetAccountPasswordModal,
            attrs: { token: res.data.token, email: res.data.email },
          })
          open()
        }
      })
  }
}

const onResize = () => {
  windowWidth.value = window.innerWidth
  if (windowWidth.value <= 1023) {
    isMobile.value = true
  } else {
    isMobile.value = false
  }
}
/***  head setup ***/
const baseAppUrl = nuxtApp.$config.public.APP_URL
const styles = []

// eslint-disable-next-line
let metaLinks = [
  { rel: 'alternate', href: `${baseAppUrl}`, hreflang: 'x-default' },
]

// Add alternate urls
mainStore.sites
  .filter((site) => site.slug === mainStore.country)
  .forEach((site) => {
    site.urls.forEach((item) => {
      metaLinks.push({
        rel: 'alternate',
        href: item.url,
        hreflang: item.locale_code,
      })
    })
  })

useHead({
  titleTemplate: '%s',
  link: metaLinks,
  style: styles,
  script: [
    {
      src: 'https://kit.fontawesome.com/91a0be131e.js',
      crossorigin: 'anonymous',
      defer: true,
    },
  ],
  htmlAttrs: {
    lang: mainStore.locale,
    dir: mainStore.isRtl ? 'rtl' : 'ltr',
  },
})
</script>
