<template>
  <DrawerModal :key="3" v-if="true">
    <section>
      <div class="flex flex-col h-screen font-light font-ff-condensed">
        <!-- Title section -->
        <div
          :class="[hasErrors ? 'mb-[22px] md:mb-3' : 'mb-3', 'px-[30px] pt-10']"
        >
          <h2
            class="2xl:text-3xl uppercase md:text-[26px] text-2xl font-[#0F0F0F] font-normal"
          >
            {{ $t('common.signup') }}
          </h2>
          <p class="text-[#404040] text-sm xl:text-base">
            {{ $t('common.do_you_already_have_an_account') }}
            <span
              @click="openSignInModal"
              class="underline cursor-pointer font-ff-condensed-pro text-primary-500"
            >
              {{ $t('common.signin') }}
            </span>
          </p>
        </div>

        <!-- Error -->
        <div class="px-[30px]">
          <div
            v-if="hasErrors"
            class="px-4 py-3 mb-2 md:mb-4 bg-red-50 rounded-[10px]"
          >
            <div class="flex items-center">
              <IconsInfoCircle class="mr-2" />
              <div class="text-sm">
                {{ message }}
              </div>
            </div>
          </div>
        </div>

        <!-- Form -->
        <div
          :class="[
            hasErrors
              ? 'xl:pt-6 md:pt-5 pt-[30px]'
              : 'pt-7 md:pt-[30px] xl:pt-[74px]',
            'px-[30px] overflow-y-scroll no-scrollbar flex-1 md:mb-[204px]',
            $config.HCAPTCHA_SITE_KEY === '' ? 'mb-[380px]' : 'mb-[320px]',
          ]"
        >
          <form method="POST">
            <div class="mb-6">
              <!-- given_name input -->
              <label
                for="given_name"
                class="text-sm font-medium text-[#0F0F0F] font-ff-condensed"
              >
                {{ $t('common.given_name') }}
              </label>
              <div class="mt-1 relative rounded-[10px] shadow-sm">
                <input
                  id="given_name"
                  v-model="given_name"
                  :class="
                    errorsObj.given_name ? 'border-red-500' : 'border-gray-300'
                  "
                  :placeholder="$t('common.given_name_placeholder')"
                  type="text"
                  name="given_name"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
              </div>
              <div
                v-if="errorsObj.given_name"
                class="mt-1.5 text-sm text-red-500"
              >
                <p
                  v-for="(errorsArray, field) in errorsObj.given_name"
                  :key="field"
                >
                  {{ errorsArray }}
                </p>
              </div>
            </div>
            <div class="mb-6">
              <!-- family_name input -->
              <label
                for="family_name"
                class="text-sm font-medium text-[#0F0F0F] font-ff-condensed"
              >
                {{ $t('common.family_name') }}
              </label>
              <div class="mt-1 relative rounded-[10px] shadow-sm">
                <input
                  id="family_name"
                  v-model="family_name"
                  :class="
                    errorsObj.family_name ? 'border-red-500' : 'border-gray-300'
                  "
                  :placeholder="$t('common.family_name_placeholder')"
                  type="text"
                  name="family_name"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
              </div>
              <div
                v-if="errorsObj.family_name"
                class="mt-1.5 text-sm text-red-500"
              >
                <p
                  v-for="(errorsArray, field) in errorsObj.family_name"
                  :key="field"
                >
                  {{ errorsArray }}
                </p>
              </div>
            </div>
            <div class="mb-6">
              <!-- Email input -->
              <label
                for="email"
                class="text-sm font-medium text-[#0F0F0F] font-ff-condensed"
              >
                {{ $t('common.signup_email') }}
              </label>
              <div class="mt-1 relative rounded-[10px] shadow-sm">
                <input
                  id="email"
                  v-model="email"
                  :placeholder="$t('common.please_enter_email')"
                  :class="
                    errorsObj.email ? 'border-red-500' : 'border-gray-300'
                  "
                  type="email"
                  name="email"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
              </div>
              <div v-if="errorsObj.email" class="mt-1.5 text-sm text-red-500">
                <p v-for="(errorsArray, field) in errorsObj.email" :key="field">
                  {{ errorsArray }}
                </p>
              </div>
            </div>
            <div class="mb-6">
              <label
                for="password"
                class="text-sm font-medium text-[#0F0F0F] font-ff-condensed"
              >
                {{ $t('common.password') }}
              </label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  :class="
                    errorsObj.password ? 'border-red-500' : 'border-gray-300'
                  "
                  :placeholder="$t('common.password_placeholder')"
                  name="password"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
                <div
                  class="absolute inset-y-0 flex items-center cursor-pointer end-0 pe-3"
                >
                  <svg
                    @click="showPassword = !showPassword"
                    class="w-5 h-5 text-[#0F0F0F]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <template v-if="!showPassword">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </template>
                    <template v-else>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                      />
                    </template>
                  </svg>
                </div>
              </div>
              <div
                v-if="errorsObj.password"
                class="text-sm mt-1.5 text-red-500"
              >
                <p
                  v-for="(errorsArray, field) in errorsObj.password"
                  v-show="hasErrors"
                  :key="field"
                >
                  {{ errorsArray }}
                </p>
              </div>
            </div>
            <div class="mb-6">
              <label
                for="repeatPassword"
                class="text-sm font-medium text-[#0F0F0F] font-ff-condensed"
              >
                {{ $t('common.password_confirmation') }}
              </label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  id="repeatPassword"
                  v-model="password_confirmation"
                  :type="showRepeatPassword ? 'text' : 'password'"
                  :class="
                    errorsObj.password ? 'border-red-500' : 'border-gray-300'
                  "
                  :placeholder="$t('common.reenter_password')"
                  name="repeatPassword"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
                <div
                  class="absolute inset-y-0 flex items-center cursor-pointer end-0 pe-3"
                >
                  <svg
                    @click="showRepeatPassword = !showRepeatPassword"
                    class="w-5 h-5 text-[#0F0F0F]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <template v-if="!showRepeatPassword">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </template>
                    <template v-else>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                      />
                    </template>
                  </svg>
                </div>
              </div>
              <div
                v-if="errorsObj.password"
                class="text-sm mt-1.5 text-red-500"
              >
                <p
                  v-for="(errorsArray, field) in errorsObj.password"
                  v-show="hasErrors"
                  :key="field"
                >
                  {{ errorsArray }}
                </p>
              </div>
            </div>
          </form>
        </div>

        <div class="pt-2 px-[30px] w-full bg-white absolute bottom-10 z-20">
          <div class="bg-gray-50">
            <VueHcaptcha
              ref="invisibleHcaptcha"
              :sitekey="$config.HCAPTCHA_SITE_KEY"
              :language="store.locale"
              @verify="onVerify"
              @expired="onExpire"
              @challengeExpired="onExpire"
              @error="onError"
              size="invisible"
              theme="dark"
            />
          </div>
          <div class="pr-3 mb-[30px]">
            <label
              :class="{
                'border-[#0F0F0F] bg-red-50 text-[#0F0F0F]':
                  errorsObj && errorsObj.accept_terms,
              }"
              class="flex text-lg border border-transparent cursor-pointer signup__policies--checkbox"
            >
              <input
                id="contact_tos"
                v-model="accept_terms"
                type="checkbox"
                class="mt-1 cursor-pointer relative bottom-[.1rem] text-[#0F0F0F] bg-gray-100 rounded-xs border-gray-300 focus:ring-[#0F0F0F] focus:ring-2"
                data-cons-preference="terms-and-conditions"
              />
              <!-- eslint-disable vue/no-v-html -->
              <span
                v-html="
                  $t('common.i_agree_with_terms_and_condition')
                    .replace(
                      'legal',
                      $i18nPath(store.pageLinks['terms-and-conditions'])
                    )
                    .replace(
                      'privacy_policy',
                      $i18nPath(store.pageLinks['privacy-policy'])
                    )
                "
                class="text-sm ml-2 text-[#0F0F0F] leading-[20px] terms signup__checkmark"
              />
            </label>
          </div>
          <button
            :disabled="loading"
            @click.prevent="onVerify"
            type="submit"
            class="w-full bg-primary-500 left-0 right-0 font-ff-condensed font-normal rounded-[10px] text-white py-[10px] md:py-2 md:px-[30px] xl:px-[82px] disabled:bg-primary-300 text-base disabled:cursor-not-allowed"
          >
            {{ $t('common.create_account') }}
          </button>
        </div>
      </div>
    </section>
  </DrawerModal>
</template>

<script setup>
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'
import SignInModal from './SignInModal.vue'
import { useTranslation } from 'i18next-vue'
import { useModal, useVfm } from 'vue-final-modal'
import IconsInfoCircle from '~/components/icons/InfoCircleIcon.vue'

const nuxtApp = useNuxtApp()
const $config = nuxtApp.$config.public
const router = useRouter()
const invisibleHcaptcha = ref(null)
const { t } = useTranslation()
const store = useMainStore()
const { signIn } = useAuth()
const vfm = useVfm()

const loading = ref(false)
const given_name = ref(null)
const family_name = ref(null)
const email = ref(null)
const password = ref(null)
const password_confirmation = ref(null)
const errorsObj = ref({})
const showPassword = ref(false)
const showRepeatPassword = ref(false)
const message = ref(null)
const accept_terms = ref(false)

const hasErrors = computed(() => {
  return Object.keys(errorsObj.value).length > 0
})

async function onVerify(token, ekey) {
  if (accept_terms.value === false) {
    nuxtApp.$toast.error(t('common.accept_terms_condition'))
    loading.value = false
  } else {
    try {
      loading.value = true

      const data = {
        given_name: given_name.value,
        family_name: family_name.value,
        email: email.value,
        company: store.country,
        password: password.value,
        password_confirmation: password_confirmation.value,
        'h-captcha-response': token,
      }

      const response = await nuxtApp.$api('api/auth/signup', {
        method: 'post',
        body: {
          given_name: given_name.value,
          family_name: family_name.value,
          email: email.value,
          company: store.country,
          password: password.value,
          password_confirmation: password_confirmation.value,
          'h-captcha-response': token,
        },
      })

      loading.value = false

      if (window.gtag) {
        window.gtag('event', 'sign_up')
      }

      nuxtApp.$toast.success(t('common.signup_success'))

      vfm.closeAll()
      const { open, close } = useModal({
        component: SignInModal,
        attrs: {
          accountEmail: email.value,
        },
      })
      open()
    } catch (e) {
      loading.value = false

      if (!e._data) {
        console.log(e, 'error')
        throw e
      } else {
        console.log(e._data.errors, 'errobj')

        errorsObj.value = e._data.errors
        message.value = e._data.message
      }
    }
  }
}

function onExpire() {
  console.log('[vue-htcapcha] Expired token')
  loading.value = false
}

function onError() {
  console.log('[vue-htcapcha] Failed to load hcaptcha')
  loading.value = false
}

const { open, close } = useModal({
  component: SignInModal,
})
const openSignInModal = open

function onSubmit() {
  errorsObj.value = {}
  loading.value = true
  invisibleHcaptcha.value.execute()
}
</script>

<style lang="postcss">
.signup__policies--checkbox {
  .terms.signup__checkmark a {
    @apply underline;
  }
}
</style>
